import React from 'react';
import { Modal } from '@material-ui/core';

const ProductDetail = (props) => {
  const { detail, setDetail, handleBudget } = props;
  let allDetail = detail || [];
  return (
    <Modal
      open={Object.keys(allDetail).length > 0}
      onClose={() => setDetail({})}                                                                            
    >
      <div id="product-detail">                  
        <div className="product-detail-img">
          <img src={allDetail.Photo} alt=""/> 
        </div>
        <div className="product-info">
          <h3 className="product-name">{allDetail.Title}</h3>
          <h4 className="product-model">
          {allDetail.InternalCode}
          <span>|</span>
          {allDetail.ModelCode}
          </h4>
          <p className="product-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, nam in dolorum suscipit quasi molestias optio accusantium quod, laborum placeat libero nobis velit vel nemo iure impedit doloremque distinctio. Optio?</p>
          <span className="button-group">
            {allDetail.isUsed ? <button disabled>Adicionado</button> : <button onClick={() => handleBudget(allDetail, 'add')}>ADICIONAR AO ORÇAMENTO</button>}
          </span>
        </div>
      </div> 
    </Modal>
  )
}

export default ProductDetail;