import React, { useState, useEffect } from "react"
import client from '../components/utils/client'
import Layout from "../components/layout/layout"

// comps
import Banner from "./common/Banner";
import ToolBar from "./produtos/ToolBar";
import SideMenu from "./produtos/SideMenu";
import OrcamentoBox from "./produtos/OrcamentoBox";
import ProductGroup from "./produtos/ProductGroup";
import ProductDetail from "./produtos/ProductDetail";
import OrcamentoModal from "./produtos/OrcamentoModal";


const ProdutosPage = () => {
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = useState({});
    
    // data
    const [allProducts, setAllProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]); 

    useEffect(() => {
        const handleGetData = () => {
            client()
                .get("product-category-all")
                .then(res => {
                    let data = res.data || [];
                    // data.Contents.map(item => {
                    //     if(item.Type === "image") {
                    //         let obj = Object.assign(item);
                    //         obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                    //         return obj;
                    //     }
                    // })
                    setCategories(data.Categories);
                    setAllProducts(data.Products);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        handleGetData();
    }, [])

    const toggleClasses = () => {
        document.querySelector('.box-orcamento').classList.toggle('open');
        document.querySelector('.open-box-orcamento').classList.toggle('open');
    }

    const reset = () => {
        toggleClasses();
    }

    const handleBudget = (product, type) => {
        document.querySelector('.box-orcamento').classList.add('open');
        document.querySelector('.open-box-orcamento').classList.add('open');
        if (type === 'add') {
            setProducts([...products.map(ell => {
                if (ell.Description === product.Description) {
                    ell.Quantity = 1;
                    ell.isUsed = true;
                }
                return ell;
            })]);
        }
        if (type === 'remove') {
            setProducts([...products.map(ell => {
                if (ell.Description === product.Description) {
                    ell.Quantity = 1;
                    ell.isUsed = false;
                }
                return ell;
            })]);
            if (products.length === 1) toggleClasses();
        }
        
    };

    useEffect(() => {
        setProducts(allProducts);
    }, [allProducts]);

    const handleOrcamentoClick = (product, op, e) => {
        if(e) {
            let value = e.target.value;
            setProducts([...products.map(ell => {
                if (ell.Description === product.Description) {
                    if(!isNaN(value)) ell.Quantity = parseInt(value);
                }
                return ell;
            })]);
        }
        else {        
            if (op === 'plus') {
                setProducts([...products.map(ell => {
                    if (ell.Description === product.Description) {
                        ell.Quantity += 1;
                    }
                    return ell;
                })]);
            }
            if (op === 'minus' && product.Quantity > 0) {
                setProducts([...products.map(ell => {
                    if (ell.Description === product.Description) {
                        if(ell.Quantity === 1) {
                            handleBudget(product, 'remove');
                        }
                        else {
                            ell.Quantity -= 1;
                        }
                    }
                    return ell;
                })]);
            }
        }
    }
    const handleSearch = (description = false) => {
        if(description.length > 0) {
            setProducts([...products.map(ell => {
                let expression = new RegExp(description, "i");
                if (!expression.test(ell.Description)) {
                    ell.hideBySearch = true;
                } else {
                    ell.hideBySearch = false;
                }
                return ell;
            })]);
            return;
        }
        setProducts([...products.map(ell => {
            ell.hideBySearch = false;
            return ell;
        })]);
    }

    const Body = () => (
        <section className="main-section container-60 margin-vertical-50px">
            <SideMenu categories={categories} products={products} handleSearch={handleSearch}/>
            <div className="content">
                <ProductGroup products={products} categories={categories} setDetail={setDetail} handleBudget={handleBudget} />
                <ProductDetail detail={detail} setDetail={setDetail} handleBudget={handleBudget} />
                {/* <ul className="pagination">
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                </ul> */}
            </div>
        </section>
    )

    return (
        <Layout infoPage={{ page: "produtos" }}>
            <div className="produtos">
                <Banner text="Produtos" />
                <ToolBar handleSearch={handleSearch} />
                <Body />
                <OrcamentoBox toggleClasses={toggleClasses} setOpen={setOpen} products={products} handleBudget={handleBudget} handleOrcamentoClick={handleOrcamentoClick}/>
            </div>
            <OrcamentoModal products={products} open={open} setOpen={setOpen} reset={reset} />
        </Layout>
    )
}

export default ProdutosPage;