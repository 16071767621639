import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import InputMask from 'react-input-mask';
import MuiAlert from '@material-ui/lab/Alert';
import {
    Snackbar,
    Modal
} from "@material-ui/core";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

const OrcamentoModal = (props) => {

    // toast
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    // const [snackbarType, setSnackbarType] = useState('info');

    // const handleClickSnackbar = () => {
    //     setSnackbarOpen(true);
    // };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const { products, open, setOpen, reset } = props;
    let productsItems = products || [];
    const formSchema = yup.object().shape({
        Name: yup.string().required("Preencha o campo"),
        Phone: yup.string(),
        CEP: yup.string().matches(/^[0-9]{5}-[0-9]{3}$/, "Digite um CEP válido").required("Preencha o campo"),
        Email: yup.string().email("Digite um endereço de email válido").required("Preencha o campo"),
        Company: yup.string(),
    });

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(formSchema)
    });

    // const handleOpenToast = (type) => {
    //     setSnackbarOpen(true);
    //     setSnackbarType(type);
    // }

    const onSubmit = (values) => {
        let objSubmit = {
            Name: values.Name,
            Phone: values.Phone,
            CEP: values.CEP,
            Email: values.Email,
            Company: values.Company,
        };

        var selProducts = [];
        productsItems.map(item => {
            if(item.isUsed) {
                selProducts.push({
                    ID: item.ID,
                    Quantity: item.Quantity,
                });
            }
            return item;
        });
        objSubmit.Items = selProducts;

        // axios
        // .post('orcamento', objSubmit)
        // .then(res => {
        //     // console.log(res);
        //     // handleOpenToast('success');
        // })
        // .catch(err => {
        //     // console.log(err);
        //     //] handleOpenToast('error');
        // });

        console.log(objSubmit);
        setOpen(false);
        reset();
    }

    const Alert = () => (
        <MuiAlert elevation={6} variant="filled" {...props} />
    );

    const SnackBar = () => {
        let text;
        var snackbarType = 'info';
        switch (snackbarType) {
            case 'success':
                text = "Sua mensagem foi enviada! Entraremos em contato o mais rápido possível.";
                break;
            case 'error':
                text = "Opa, algo deu errado! :(";
                break;
            case 'info':
                text = "Estamos processando, por favor aguarde.";
                break;
            default:

        }
        return (
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
                    {text}
                </Alert>
            </Snackbar>
        );
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div id="orcamento-modal">
                {productsItems.length === 0
                    ?
                    <h2 style={{ flex: 1, textAlign: "center", color: "#fff" }}>
                        Selecione ao menos um item para solicitar o orçamento
                    </h2>
                    :
                    <>
                        <div className="left">
                            <h1>Aqui seu orçamento é rápido e fácil</h1>
                            <p>Preencha os dados ao lado e nossa equipe irá entrar em contato com você.</p>
                        </div>
                        <div className="right">
                            <div className="black-square"></div>
                            <form onSubmit={handleSubmit(onSubmit)} className="form">
                                <>
                                    <Controller
                                        as={<input aria-label="nome-input" />}
                                        // className="required"
                                        name="Name"
                                        placeholder="Nome*"
                                        control={control}
                                        defaultValue=""
                                    />
                                    {errors.Name && <label>{errors.Name.message}</label>}
                                </>
                                <>
                                    <Controller
                                        as={<InputMask mask="(99) 99999-9999" maskChar="" />}
                                        name="Phone"
                                        placeholder="Telefone"
                                        control={control}
                                        defaultValue=""
                                    />
                                    {errors.Phone && <label>{errors.Phone.message}</label>}
                                </>
                                <>
                                    <Controller
                                        as={<InputMask mask="99999-999" maskChar="" />}
                                        name="CEP"
                                        placeholder="CEP*"
                                        control={control}
                                        defaultValue=""
                                    />
                                    {errors.CEP && <label>{errors.CEP.message}</label>}
                                </>
                                <>
                                    <Controller
                                        as={<input aria-label="email-input" />}
                                        name="Email"
                                        placeholder="E-mail*"
                                        control={control}
                                        defaultValue=""
                                    />
                                    {errors.Email && <label>{errors.Email.message}</label>}
                                </>
                                <>
                                    <Controller
                                        as={<input aria-label="empresa-input" />}
                                        name="Company"
                                        placeholder="Empresa"
                                        control={control}
                                        defaultValue=""
                                    />
                                    {errors.Company && <label>{errors.Company.message}</label>}
                                </>
                                <button type="submit">SOLICITAR ORÇAMENTO</button>
                            </form>
                        </div>
                        <div className="final">
                            <h1>Seu pedido foi enviado!</h1>
                            <p>Logo nossa equipe entrará em contato com você.</p>
                        </div>
                    </>
                }
                <SnackBar />
            </div>
        </Modal>
    )
}

export default OrcamentoModal;