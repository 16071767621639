import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Typography } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';

const SideMenu = (props) => {
    const { products, categories, handleSearch } = props;
    
    // useEffect(() => {
    //     localStorage.getItem('lastSelClicked');
    //     localStorage.setItem('lastSelClicked', e.target.children[0].textContent);
    // }, []);
    const handleItemClick = (e) => {
        localStorage.setItem('lastSelClicked', e.target.children[0].textContent);
        let item = e.target.parentElement;
        if (item.tagName === "SPAN") item = item.parentElement;
        item.classList.toggle("selected");
    };

    let categoriesItems = categories || [];

    return (
        <div className="side-menu">
            <h1 className="side-menu-title">
                Categorias
            </h1>            
            <div className="d-f-a-c" onClick={handleSearch} onKeyDown={handleSearch} role="button" tabIndex={0} style={{cursor: "pointer"}}>
                <Typography variant="h6" className="side-menu-reset">Atualizar</Typography>
                <SyncIcon/>
            </div>
            {categoriesItems.map((categoria, i) => (
                <div className={"item " + (localStorage.getItem('lastSelClicked') === categoria.Title? 'wow selected' : '')} key={categoria.ID}>
                    <span className="item-title" onClick={handleItemClick} onKeyDown={handleItemClick} role="button" tabIndex={-1}>
                        <h4>{categoria.Title}</h4>
                        <ChevronRightIcon id="arrow-icon" fontSize="small" />
                    </span>
                    <ul className="item-list" key={i}>
                    {
                        products.filter((item2, j) => item2.CategoryID === categoria.ID).map((item2, j) => {
                            return (
                                <li className="list-item-title" key={j} onClick={(e) => handleSearch(item2.Description)} onKeyDown={(e) => handleSearch(item2.Description)} role="presentation">{item2.Description}</li>
                            )
                        })
                    }
                    </ul>
                </div>
            ))}
        </div>
    )
}

export default SideMenu;